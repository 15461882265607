<template>
	<!-- 个人中心 邀请人员列表 -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ paddingTop: 0, paddingBottom: '16px' }">
		<template #title>
			<h6 class="font-semibold m-0">最新邀请</h6>
		</template>
		<a-list class="conversations-list" item-layout="horizontal" :split="false" :data-source="shareUserList">
			<a-list-item slot="renderItem" slot-scope="item">
				<a-list-item-meta :title="'UID：' + item.id" :description="'邀请时间：' + item.createtime">
					<a-avatar slot="avatar" :size="48" shape="square" :src="item.avatar" />
				</a-list-item-meta>
			</a-list-item>
		</a-list>
	</a-card>
</template>

<script>
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
	export default {
		data() {
			return {}
		},
		computed: {
			...mapGetters("user", ["statisticInfo"]),
			shareUserList() {
				return this.statisticInfo.share_user_list
			}
		},
		created() {}
	}
</script>
